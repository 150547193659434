import { gql } from "@apollo/client"

const GET_ALL_ACTION_PLANS = gql`
  query previewActionPlan($surveyId: String!) {
    previewActionPlan(surveyId: $surveyId) {
      message
      status
      body {
        _id
        steps {
          actionPlanId
          createdAt
          default
          id
          questions {
            body {
              questions {
                answers
                id
                title
              }
              resources {
                description
                mediaType
                title
                url
              }
              suggestedActions
              suggestedPhrases
            }
            sixStepsQuestionId
            title
          }
          title
          type
          updatedAt
        }
        surveyCount
        surveyTitle
        title
        createdAt
        default
        organisationId
        planType
        status
      }
    }
  }
`

export default GET_ALL_ACTION_PLANS
